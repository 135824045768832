import React from 'react';
import './CVComponent.css';

function CVComponent() {
    return (
        <div className="cv-component">
            <h2>Basil Furrer</h2>
            <p>Computer Science Student</p>

            <h3>Education</h3>
            <ul>
                <li><strong>University of Zürich</strong> - B.Sc. in Computer Science, Software Systems (2022 - 2025)
                </li>
            </ul>

            <h3>Experience</h3>
            <ul>
                <li><strong>Mettler Toledo</strong> - Internship Software Engineering (Summer 2024)</li>
            </ul>

            <h3>Skills</h3>
            <ul>
                <li>JavaScript, React</li>
                <li>Java</li>
                <li>C#, WPF</li>
                <li>Python</li>
                <li>R</li>
                <li>Swift</li>
                <li>Git</li>
            </ul>
        </div>
    );
}

export default CVComponent;
