// src/components/BlogComponent.js

import React from 'react';
import BlogPost from './BlogPost'; // Import the BlogPost component
import './BlogComponent.css';

function BlogComponent() {
    return (
        <div className="blog-component">
            <h2>Latest Posts</h2>

            <BlogPost
                title="No new posts yet"
                body="No blog entries have been posted yet. Check back later for updates."
                link="https://www.basilfurrer.com"
                linkText="Back to homepage"
                italicLine="Published September, 2024"
            />

            {/* Add more BlogPost components as needed */}
        </div>
    );
}

export default BlogComponent;
