import React from 'react';
import './Projects.css';
import BackgroundImage from "../Utilities/BackgroundImage";
import TextBox from '../Boxes/TextBox';
import MazeSolver from "../ProjectsComponents/MazeSolver/MazeSolver";
import ProjectBox from "../ProjectsComponents/ProjectBox";
import SocialBox from "../Boxes/SocialBox";


function Projects() {
    return (
        <>
            <BackgroundImage image={"black"}/>
            <div className="layout">
                <div className="projects-description">
                    <TextBox
                        title="Hi! Glad you're here!"
                        body="Here you can find some of my projects and widgets."
                        textColor="white"
                    />
                    <SocialBox
                        title="My Socials"
                        socialLinks={[
                            {platform: "instagram", link: "https://www.instagram.com/basilfurrer"},
                            {platform: "github", link: "https://github.com/b1s9l"},
                        ]}
                        iconColor="white"
                        textColor="white"
                    />

                </div>
                <div className="projects-content">
                    <ProjectBox title="Maze Generator / Solver"
                                date="2025"
                                description={`Here we are generating a perfect maze, without loops or isolated areas, using the Hilbert-Lookahead algorithm.
                                For each cell, we are selecting a random neighbor which lies further down on the hilbert curve.
                                We can then solve the maze visually using one of the available algorithms.`}
                                addon={<MazeSolver />}
                    />
                    <ProjectBox title="More projects coming soon..." url="instagram.com/basilfurrer" urlText="Follow me on Instagram."/>
                </div>
            </div>

        </>
    );
}

export default Projects;
