import React, { useRef, useEffect, useState } from "react";
import "./MazeSolver.css";

const algorithms = {
    DFS: async function* (start, end, getCell, neighbors) {
        const visited = new Set();
        const stack = [start];

        while (stack.length > 0) {
            const current = stack.pop();
            const key = `${current.x},${current.y}`;
            if (visited.has(key)) continue;
            visited.add(key);
            yield current;
            if (current.x === end.x && current.y === end.y) return;
            for (const n of neighbors(current)) {
                stack.push(n);
            }
        }
    },
    BFS: async function* (start, end, getCell, neighbors) {
        const visited = new Set();
        const queue = [start];

        while (queue.length > 0) {
            const current = queue.shift();
            const key = `${current.x},${current.y}`;
            if (visited.has(key)) continue;
            visited.add(key);
            yield current;
            if (current.x === end.x && current.y === end.y) return;
            for (const n of neighbors(current)) {
                queue.push(n);
            }
        }
    },
    AStar: async function* (start, end, getCell, neighbors) {
        const visited = new Set();
        const openSet = [start];
        const gScore = {};
        gScore[`${start.x},${start.y}`] = 0;

        const heuristic = (a, b) => Math.abs(a.x - b.x) + Math.abs(a.y - b.y);

        while (openSet.length > 0) {
            openSet.sort((a, b) =>
                (gScore[`${a.x},${a.y}`] + heuristic(a, end)) - (gScore[`${b.x},${b.y}`] + heuristic(b, end))
            );
            const current = openSet.shift();
            const key = `${current.x},${current.y}`;
            if (visited.has(key)) continue;
            visited.add(key);
            yield current;
            if (current.x === end.x && current.y === end.y) return;

            for (const n of neighbors(current)) {
                const nKey = `${n.x},${n.y}`;
                const tentativeG = gScore[key] + 1;
                if (!(nKey in gScore) || tentativeG < gScore[nKey]) {
                    gScore[nKey] = tentativeG;
                    openSet.push(n);
                }
            }
        }
    },
    Dijkstra: async function* (start, end, getCell, neighbors) {
        const visited = new Set();
        const dist = {};
        const queue = [start];
        dist[`${start.x},${start.y}`] = 0;

        while (queue.length > 0) {
            queue.sort((a, b) =>
                dist[`${a.x},${a.y}`] - dist[`${b.x},${b.y}`]
            );
            const current = queue.shift();
            const key = `${current.x},${current.y}`;
            if (visited.has(key)) continue;
            visited.add(key);
            yield current;
            if (current.x === end.x && current.y === end.y) return;

            for (const n of neighbors(current)) {
                const nKey = `${n.x},${n.y}`;
                const alt = dist[key] + 1;
                if (!(nKey in dist) || alt < dist[nKey]) {
                    dist[nKey] = alt;
                    queue.push(n);
                }
            }
        }
    },
    GreedyBestFirst: async function* (start, end, getCell, neighbors) {
        const visited = new Set();
        const queue = [start];
        const heuristic = (a, b) => Math.abs(a.x - b.x) + Math.abs(a.y - b.y);

        while (queue.length > 0) {
            queue.sort((a, b) =>
                heuristic(a, end) - heuristic(b, end)
            );
            const current = queue.shift();
            const key = `${current.x},${current.y}`;
            if (visited.has(key)) continue;
            visited.add(key);
            yield current;
            if (current.x === end.x && current.y === end.y) return;

            for (const n of neighbors(current)) {
                queue.push(n);
            }
        }
    },

    RandomMouse: async function* (start, end, getCell, neighbors) {
        let current = start;

        while (true) {
            yield current;
            if (current.x === end.x && current.y === end.y) return;

            const options = neighbors(current);
            if (options.length === 0) return; // no way forward

            current = options[Math.floor(Math.random() * options.length)];
            await new Promise(r => setTimeout(r, 0));
        }
    },

    WallFollower: async function* (start, end, getCell, neighbors) {
        const dirs = [
            { dx: 0, dy: -1 }, // up
            { dx: 1, dy: 0 },  // right
            { dx: 0, dy: 1 },  // down
            { dx: -1, dy: 0 }  // left
        ];

        const wallOpen = (cell, dirIndex) => getCell(cell.x, cell.y)?.[dirIndex] === false;

        let current = start;
        let dir = 0; // facing up

        while (true) {
            yield current;
            if (current.x === end.x && current.y === end.y) return;

            // Right-hand rule logic:
            let checkDir = (dir + 1) % 4;
            let tried = 0;

            while (tried < 4) {
                if (wallOpen(current, checkDir)) {
                    const { dx, dy } = dirs[checkDir];
                    current = { x: current.x + dx, y: current.y + dy };
                    dir = checkDir;
                    break;
                } else {
                    checkDir = (checkDir + 3) % 4; // rotate left
                    tried++;
                }
            }

            await new Promise(r => setTimeout(r, 0));
        }
    },

    BidirectionalBFS: async function* (start, end, getCell, neighbors) {
        const key = (c) => `${c.x},${c.y}`;
        const visitedStart = new Set([key(start)]);
        const visitedEnd = new Set([key(end)]);
        const queueStart = [start];
        const queueEnd = [end];

        while (queueStart.length > 0 && queueEnd.length > 0) {
            // Expand from start
            const currentA = queueStart.shift();
            yield currentA;
            if (visitedEnd.has(key(currentA))) return;

            for (const n of neighbors(currentA)) {
                const nk = key(n);
                if (!visitedStart.has(nk)) {
                    visitedStart.add(nk);
                    queueStart.push(n);
                }
            }

            // Expand from end
            const currentB = queueEnd.shift();
            yield currentB;
            if (visitedStart.has(key(currentB))) return;

            for (const n of neighbors(currentB)) {
                const nk = key(n);
                if (!visitedEnd.has(nk)) {
                    visitedEnd.add(nk);
                    queueEnd.push(n);
                }
            }

            await new Promise(r => setTimeout(r, 0));
        }
    },
    IterativeDeepeningDFS: async function* (start, end, getCell, neighbors) {
        const maxDepth = 100;

        async function* DLS(node, depth, visited) {
            const key = `${node.x},${node.y}`;
            if (visited.has(key)) return false;
            visited.add(key);
            yield node;

            if (node.x === end.x && node.y === end.y) return true;
            if (depth === 0) return false;

            for (const n of neighbors(node)) {
                const result = yield* DLS(n, depth - 1, visited);
                if (result === true) return true;
            }

            return false;
        }

        for (let depth = 1; depth <= maxDepth; depth++) {
            const visited = new Set();
            const gen = DLS(start, depth, visited);
            for await (const cell of gen) {
                yield cell;
                if (cell.x === end.x && cell.y === end.y) return;
            }
        }
    }


    //more algos

    //end of solve algorithms




};

const MazeSolver = () => {
    const canvasRef = useRef(null);
    const [size, setSize] = useState(8);
    const [cellSize, setCellSize] = useState(0);
    const [hilbertPath, setHilbertPath] = useState([]);
    const [cells, setCells] = useState({});
    const [selectedAlgo, setSelectedAlgo] = useState("DFS");
    const [visitedCount, setVisitedCount] = useState(0);
    const [isGenerating, setIsGenerating] = useState(false);
    const [isSolving, setIsSolving] = useState(false);
    const runIdRef = useRef(0);


    const getValidSizes = () => {
        const sizes = [];
        for (let i = 1; i <= 128; i++) {
            if ((i & (i - 1)) === 0) sizes.push(i);
        }
        return sizes;
    };

    useEffect(() => {
        const canvas = canvasRef.current;
        setCellSize(canvas.width / size);
        drawGrid(canvas.getContext("2d"));
    }, [size]);

    const drawGrid = (ctx) => {
        ctx.clearRect(0, 0, 500, 500);
        ctx.strokeStyle = "#eee";
        ctx.lineWidth = 1;
        for (let x = 0; x <= size; x++) {
            drawLine(ctx, x * cellSize, 0, x * cellSize, size * cellSize);
        }
        for (let y = 0; y <= size; y++) {
            drawLine(ctx, 0, y * cellSize, size * cellSize, y * cellSize);
        }
    };

    const generateHilbertCurve = (n) => {
        const d2xy = (n, d) => {
            let rx, ry, s, t = d;
            let x = 0, y = 0;
            for (s = 1; s < n; s *= 2) {
                rx = 1 & (t / 2);
                ry = 1 & (t ^ rx);
                if (ry === 0) {
                    if (rx === 1) {
                        x = s - 1 - x;
                        y = s - 1 - y;
                    }
                    [x, y] = [y, x];
                }
                x += s * rx;
                y += s * ry;
                t = Math.floor(t / 4);
            }
            return [x, y];
        };

        const total = n * n;
        const path = [];
        for (let i = 0; i < total; i++) {
            const [x, y] = d2xy(n, i);
            path.push({ x, y });
        }
        return path;
    };

    const drawHilbertCurve = (ctx, path) => {
        ctx.strokeStyle = "rgba(0, 0, 255, 0.4)";
        ctx.lineWidth = 2;
        ctx.beginPath();
        ctx.moveTo(path[0].x * cellSize + cellSize / 2, path[0].y * cellSize + cellSize / 2);
        path.forEach(point => {
            ctx.lineTo(point.x * cellSize + cellSize / 2, point.y * cellSize + cellSize / 2);
        });
        ctx.stroke();
    };

    const drawWalls = (ctx, cell, walls) => {
        const x = cell.x * cellSize;
        const y = cell.y * cellSize;
        ctx.strokeStyle = "black";
        ctx.lineWidth = 2;
        if (walls[0]) drawLine(ctx, x, y, x + cellSize, y);
        if (walls[1]) drawLine(ctx, x + cellSize, y, x + cellSize, y + cellSize);
        if (walls[2]) drawLine(ctx, x + cellSize, y + cellSize, x, y + cellSize);
        if (walls[3]) drawLine(ctx, x, y + cellSize, x, y);
    };

    const drawLine = (ctx, x1, y1, x2, y2) => {
        ctx.beginPath();
        ctx.moveTo(x1, y1);
        ctx.lineTo(x2, y2);
        ctx.stroke();
    };

    const generateMaze = async () => {
        setIsGenerating(true);
        setIsSolving(false); // In case solving was running
        runIdRef.current += 1;
        const myRunId = runIdRef.current;
        setVisitedCount(0);

        const canvas = canvasRef.current;
        const ctx = canvas.getContext("2d");
        drawGrid(ctx);

        const path = generateHilbertCurve(size);
        setHilbertPath(path);

        drawHilbertCurve(ctx, path);

        const visited = new Set();
        const newCells = {};
        const available = [...path];

        const totalSteps = available.length;
        const duration = 1000;
        const startTime = Date.now();
        let stepCount = 0;

        while (stepCount < totalSteps) {
            if (runIdRef.current !== myRunId) return;

            const now = Date.now();
            const elapsed = now - startTime;
            const targetSteps = Math.min(totalSteps, Math.floor((elapsed / duration) * totalSteps));

            while (stepCount < targetSteps) {
                const index = Math.floor(Math.random() * available.length);
                const current = available.splice(index, 1)[0];
                const currentIndex = path.findIndex(p => p.x === current.x && p.y === current.y);
                const currentKey = `${current.x},${current.y}`;
                visited.add(currentKey);

                if (!newCells[currentKey]) newCells[currentKey] = [true, true, true, true];

                const forwardNeighbors = path.slice(currentIndex + 1);
                const options = [
                    { x: current.x, y: current.y - 1, wall: [0, 2] },
                    { x: current.x + 1, y: current.y, wall: [1, 3] },
                    { x: current.x, y: current.y + 1, wall: [2, 0] },
                    { x: current.x - 1, y: current.y, wall: [3, 1] }
                ];

                const validNeighbors = options.filter(n =>
                    forwardNeighbors.find(p => p.x === n.x && p.y === n.y)
                );

                let next = validNeighbors[Math.floor(Math.random() * validNeighbors.length)];

                if (next) {
                    newCells[currentKey][next.wall[0]] = false;
                    const neighborKey = `${next.x},${next.y}`;
                    if (!newCells[neighborKey]) newCells[neighborKey] = [true, true, true, true];
                    newCells[neighborKey][next.wall[1]] = false;
                }

                ctx.fillStyle = "rgba(0, 200, 0, 0.3)";
                ctx.fillRect(current.x * cellSize, current.y * cellSize, cellSize, cellSize);
                drawWalls(ctx, current, newCells[currentKey]);
                stepCount++;
            }

            await new Promise(r => requestAnimationFrame(r));
        }

        ctx.clearRect(0, 0, canvas.width, canvas.height);
        drawGrid(ctx);

        for (let key in newCells) {
            const [x, y] = key.split(",").map(Number);
            drawWalls(ctx, { x, y }, newCells[key]);
        }

        ctx.fillStyle = "limegreen";
        const first = path[0];
        const last = path[path.length - 1];
        ctx.fillRect(first.x * cellSize, first.y * cellSize, cellSize, cellSize);
        ctx.fillRect(last.x * cellSize, last.y * cellSize, cellSize, cellSize);

        setCells(newCells);
        setIsGenerating(false);
    };

    const solveMaze = async () => {
        if (!Object.keys(cells).length) return;

        setIsSolving(true);
        runIdRef.current += 1;
        const myRunId = runIdRef.current;
        setVisitedCount(0);

        const canvas = canvasRef.current;
        const ctx = canvas.getContext("2d");
        drawGrid(ctx);

        for (let key in cells) {
            const [x, y] = key.split(",").map(Number);
            drawWalls(ctx, { x, y }, cells[key]);
        }

        const path = generateHilbertCurve(size);
        const start = path[0];
        const end = path[path.length - 1];

        // Redraw start and end cells
        ctx.fillStyle = "limegreen";
        ctx.fillRect(start.x * cellSize, start.y * cellSize, cellSize, cellSize);
        ctx.fillRect(end.x * cellSize, end.y * cellSize, cellSize, cellSize);

        const getCell = (x, y) => cells[`${x},${y}`];

        const getNeighbors = (cell) => {
            const dirs = [
                { dx: 0, dy: -1, wall: 0 },
                { dx: 1, dy: 0, wall: 1 },
                { dx: 0, dy: 1, wall: 2 },
                { dx: -1, dy: 0, wall: 3 },
            ];
            return dirs
                .filter(d => !getCell(cell.x, cell.y)?.[d.wall])
                .map(d => ({ x: cell.x + d.dx, y: cell.y + d.dy }));
        };

        const algo = algorithms[selectedAlgo];
        for await (const cell of algo(start, end, getCell, getNeighbors)) {
            if (runIdRef.current !== myRunId) return;

            if ((cell.x !== start.x || cell.y !== start.y) && (cell.x !== end.x || cell.y !== end.y)) {
                ctx.fillStyle = "rgba(255, 200, 0, 0.4)";
                ctx.fillRect(cell.x * cellSize, cell.y * cellSize, cellSize, cellSize);
                setVisitedCount(prev => prev + 1);
            }
            await new Promise(r => setTimeout(r, 50));
        }
        setIsSolving(false);

    };

    return (
        <div className="maze-widget">
            <label>
                Size:
                <select value={size} onChange={e => {
                    setSize(parseInt(e.target.value));
                    runIdRef.current += 1; // stop any ongoing loops
                    setVisitedCount(0);
                    setCells({});
                }}
                >
                    {getValidSizes().map(s => (
                        <option key={s} value={s}>{s} x {s}</option>
                    ))}
                </select>
            </label>
            <label>
                Algorithm:
                <select value={selectedAlgo} onChange={e => setSelectedAlgo(e.target.value)}>
                    {Object.keys(algorithms).map(name => (
                        <option key={name} value={name}>{name}</option>
                    ))}
                </select>
            </label>
            <button onClick={generateMaze}>Generate Maze</button>
            <button onClick={solveMaze} disabled={isGenerating || isSolving || Object.keys(cells).length === 0}>
                Solve Maze
            </button>
            <div className="ms-text">Visited: {visitedCount}</div>
            <canvas ref={canvasRef} width={500} height={500}/>
        </div>
    );
};

export default MazeSolver;
