import React from 'react';
import './ProjectBox.css';

function ProjectBox({ title, date, description, addon, url, urlText, italicLine, textColor = "white" }) {
    const textStyle = { color: textColor }; // Style object for dynamic text color
    const boxStyle = { borderColor: textColor }; // Style object for dynamic border color

    return (
        <div className="project-box" style={boxStyle}> {/* Apply dynamic border color */}
            {title && <div className="text-title" style={textStyle}>{title}</div>}
            <div className="text-body" style={textStyle}>
                {typeof description === 'string' ? description.split('\n').map((line, index) => (
                        <span key={index}>
                            {line.trim()}
                            <br/>
                        </span>
                )) : description}
            </div>

            {addon && <div className="project-addon">
                {addon}
            </div>}
            {url && <div className="text-link" style={textStyle}>{urlText}</div>}
            {italicLine && <div className="italic-line" style={textStyle}>{italicLine}</div>}
            {date && <div className="text-date" style={textStyle}>{date}</div>}
        </div>
    );
}

export default ProjectBox;
